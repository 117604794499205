import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import ContactForm from "../pages/contact/form";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StockGainerPersonal from "../components/StockGainerPersonal";
import ChartTwo from "../components/ChartTwo";
import ChartBandLight from "../components/ChartBandLight";
import ChartThreeLight from "../components/ChartThreeLight";

export const TermsandconditionsPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <Helmet>
      <title>Swiftfox | Terms And Conditions</title>
    </Helmet>
    <div className="hero-section faq-hero">
      <div className="hero-main">
        <div className="hero-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h1
                  ref={ref}
                  initial={{ opacity: 0 }}
                  animate={inView ? { opacity: 1 } : { opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}
                >
                  Terms and Conditions
                </motion.h1>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4
                  ref={ref}
                  initial={{ opacity: 0 }}
                  animate={inView ? { opacity: 1 } : { opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}
                >
                  These Terms govern your use of Swiftfox and the services we
                  offer. By accessing our website or using our services, you
                  agree to be bound by these Terms.{" "}
                </motion.h4>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>

    <div className="callout-section white-bg dark-text">
      <div className="callout-main">
        <div className="callout-main-content stack-items">
         <p>
         Use of Services:
         <br></br> 
You may use our services only as permitted by law and according to the terms of any agreements you have entered into with us. We may suspend or stop providing services to you if you do not comply with our terms or policies.
<br></br> 
<br></br> 

Your Account: 
<br></br> 

You are responsible for your own account, including the security of your passwords and the activity that occurs under your account.
Intellectual Property: All contents of the site, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the site, are the property of Swiftfox or its suppliers and protected by copyright and other laws.
<br></br> 
<br></br> 

Liability: 
<br></br> 

Swiftfox will not be liable for any damages or losses arising from your use of our services or from unauthorized access to or alteration of your transmissions or data.
Amendments to Terms: We reserve the right to modify these terms at any time. Your continued use of Swiftfox after any such changes constitutes your acceptance of the new Terms and Conditions.
<br></br> 
<br></br> 

Governing Law:
<br></br> 

These terms will be governed by and interpreted in accordance with the laws of the jurisdiction in which our company is registered, without regard to its conflict of law provisions.
<br></br> 
<br></br> 

Contact Us: 
<br></br> 

For any questions about these Terms, please contact us at support@swiftfox.co.uk.
         </p>
        </div>
      </div>
    </div>
  </div>
);

TermsandconditionsPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const TermsandconditionsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <TermsandconditionsPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

TermsandconditionsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default TermsandconditionsPage;

export const pageQuery = graphql`
  query TermsandconditionsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
